import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const CustomerService = () => {}
//OWNER
CustomerService.GetCustomerOwnerList = async(payload, queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerOwner?${queryString}`, payload)
}

CustomerService.GetCustomerOwnerById = async(ownerId)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerOwner/${ownerId}`, {})
}

CustomerService.InsertCustomerOwner = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/setting/customerOwner`, payload)
 }  

 CustomerService.UpdateCustomerOwner = async(payload, ownerId)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/customerOwner/${ownerId}`, payload)
 } 

CustomerService.DeleteCustomerOwner = async(ownerId)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/setting/customerOwner/${ownerId}`, {})
}


//COMPANY
CustomerService.GetCustomerCompanyList = async(ownerId, payload={},queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerCompany/owner/${ownerId}?${queryString}`, payload)
}

CustomerService.GetCustomerCompanyById = async(companyId)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerCompany/${companyId}`, {})
}

CustomerService.InsertCustomerCompany = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/setting/customerCompany`, payload)
 }  

 CustomerService.UpdateCustomerCompany = async(payload, companyId)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/customerCompany/${companyId}`, payload)
 } 

CustomerService.DeleteCustomerCompany = async(companyId)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/setting/customerCompany/${companyId}`, {})
}



//SUB COMPANY
CustomerService.GetCustomerSubCompanyListAll = async(queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerSubCompany?${queryString}`, {})
}

CustomerService.GetCustomerSubCompanyList = async(companyId, payload={}, queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerSubCompany/company/${companyId}?${queryString}`, payload)
}

CustomerService.GetCustomerSubCompanyById = async(companyId)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerSubCompany/${companyId}`, {})
}

CustomerService.InsertCustomerSubCompany = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/setting/customerSubCompany`, payload)
 }  

 CustomerService.UpdateCustomerSubCompany = async(payload, companyId)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/customerSubCompany/${companyId}`, payload)
 } 

CustomerService.DeleteCustomerSubCompany = async(subCompanyId)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/setting/customerSubCompany/${subCompanyId}`, {})
}

//BRANCH
CustomerService.GetCustomerBranchList = async(subCompanyId, payload={}, queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerBranch/subcompany/${subCompanyId}?${queryString}`, payload)
}

CustomerService.SearchBranchStock = async(subCompanyId, queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerBranch/search/${subCompanyId}/${queryString}`, {})
}

CustomerService.CheckDupBranchCode = async(branchCode, queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerBranch/checkBranchCode/${branchCode}?${queryString}`, {})
}

CustomerService.GetCountry = async(payload={})=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/country`, payload)
}

CustomerService.GetCustomerBranchById = async(branchId)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerBranch/${branchId}`, {})
}

CustomerService.InsertCustomerBranch = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/setting/customerBranch`, payload)
 }  

 CustomerService.UpdateCustomerBranch = async(payload, branchId)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/customerBranch/${branchId}`, payload)
 } 

 CustomerService.UpdateCustomerCountry = async(payload)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/country/`, payload)
 } 

 CustomerService.MoveBranchToNewBrand = async(payload)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/customerBranch/move-branch/`, payload)
 } 

CustomerService.DeleteCustomerBranch = async(branchId)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/setting/customerBranch/${branchId}`, {})
}
 
 export default CustomerService