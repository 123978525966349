export default [
  { key: "checked", label: "#", sortable: false , thStyle:{color:"white",width: "3% !important"}},
  { key: "no", label: "ลำดับที่", sortable: true , thStyle:{color:"white",width: "6% !important"}},
  {
    key: "branchCode",
    label: "รหัสสาขา",
    thStyle:{color:"white",width: "6% !important"}
  },  
  {
    key: "branchName",
    label: "ชื่อสาขา",
    thStyle:{color:"white",width: "15% !important"}
  },  
  {
    key: "latitude",
    label: "Latitude",
    thStyle:{color:"white",width: "8% !important"}
  },  
  {
    key: "longtitude",
    label: "Longtitude",
    thStyle:{color:"white",width: "7% !important"}
  },  

  {
    key: "address",
    label: "ที่อยู่",
    thStyle:{color:"white"}
  },  
  {
    key: "status",
    label: "สถานะ",
    thStyle:{color:"white", textAlign: "center", width: "8% !important"}
  },  
  { 
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { width: "12% !important", textAlign: "center", color:"white" },
  },
];
